import { PixiUtils } from "./pixi.utils";
import * as PIXI from "pixi.js";
import React, { useEffect, useMemo, useState } from "react";
import { BubblesUtils, appConfig } from "./bubbles.utils";
import Modal from "../Modal";
import { useSelector } from "react-redux";
import ChartData from "../Modal/ChartData";
import { bubbleContentFormator } from "../../utils/formators";
import { setPagination } from "../../reducers/slices/StockSlice";
import { useDispatch } from "react-redux";

const { maxCircleSize, minCircleSize } = appConfig;



export default function Bubbles() {
  const dispatch = useDispatch();

  const [circles, setCircles] = useState(null);
  const appRef = React.useRef(null);
  const [stockData, setStocksData] = useState([])

  const bubbleSort = useSelector((state) => state.Settings.selectedFilter);
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const bubbleContents = useSelector((state) => state.Settings.bubbleContent);
  const activeButton = useSelector((state) => state.Settings.selectedFilter);

  const Stocks = useSelector((state) => state.Stocks.dailyStocks);

  // useEffect(() => {
  //   const shuffleData = (data) => {
  //     const shuffledData = [...data];
  //     for (let i = shuffledData.length - 1; i > 0; i--) {
  //       const j = Math.floor(Math.random() * (i + 1));
  //       [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
  //     }
  //     return shuffledData;
  //   };
  
  //   if (Stocks && Stocks.length > 0) {
  //     const shuffledData = shuffleData(Stocks);
  //     setStocksData(shuffledData);
  //   }
  // }, [Stocks]);
  

  // Remove dispatch from here
  // dispatch(setPagination(Stocks.length)); // <-- Remove this line

  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // ─────────────────────────────────────────────────────────────
  // 5) PAGINATION ON TOP OF SORTED DATA
  // ─────────────────────────────────────────────────────────────

  function isMobile() {
// window.innerWidth <= 1100; // Adjust the breakpoint as needed
return  true
  }

  // Assign startIndexNum and endIndexNum based on screen size
  let startIndexNum = 0; // zero-based index
  let endIndexNum = 0;
  if (isMobile()) {
    // debugger
    // Mobile screen: set to full range
    if(selectedPagination){
      const [startStr, endStr] = selectedPagination.split("-");
      startIndexNum = Math.max(0, parseInt(startStr, 10) - 1); // zero-based index
      endIndexNum = Math.min(parseInt(endStr, 10), Stocks.length);
    }else{
        startIndexNum = 0;
    endIndexNum = Stocks.length;
    }

  } else {
    startIndexNum = 0;
    endIndexNum = Stocks.length;
    // Larger screens: use original logic
  }

  const paginatedStocks = useMemo(() => {
    return Stocks.slice(startIndexNum, endIndexNum);
  }, [startIndexNum, endIndexNum, Stocks]);

  // Corrected scalingFactor useMemo
  const scalingFactor = useMemo(() => {
    return BubblesUtils.getScalingFactor(
      paginatedStocks,
      bubbleSort
    );
  }, [bubbleSort, paginatedStocks]);

  // Dispatch setPagination when paginatedStocks changes
  useEffect(() => {
    dispatch(setPagination(Stocks.length));
  }, [dispatch, paginatedStocks.length]);

  useEffect(() => {
    if (Stocks) {
      const scalingFactor = BubblesUtils.getScalingFactor(
        Stocks.slice(startIndexNum, endIndexNum),
        bubbleSort
      );
      const shapes = BubblesUtils.generateCircles(
        Stocks.slice(startIndexNum, endIndexNum),
        scalingFactor,
        bubbleSort,
        colorScheme,
        bubbleContents.value,
        activeButton
      );

      setCircles(shapes);
    }
  }, [colorScheme, bubbleSort, Stocks, startIndexNum, endIndexNum, bubbleContents, activeButton]);

  useEffect(() => {
    if (!circles) return;
    PIXI.settings.RESOLUTION = window.devicePixelRatio;
    const app = new PIXI.Application({
      width: window.innerWidth || 10,
      height: (window.innerHeight - 170) || 10,
      backgroundColor: "#222222",
      antialias: true,
      autoDensity: true,
    });

    const appContainer = appRef.current;
    appContainer?.appendChild(app.view);
    appContainer?.children[0].addEventListener("click", (e) =>
      BubblesUtils.handleEmptySpaceClick(e, circles)
    );

    const imageSprites = [];
    const textSprites = [];
    const text2Sprites = [];
    const circleGraphics = [];

    circles.forEach((circle) => {
      const container = PixiUtils.createContainer(circle);

      const circleGraphic = new PIXI.Sprite(
        PixiUtils.createGradientTexture(circle.radius * 4, circle.color)
      );

      circleGraphic.anchor.set(0.5);
      circleGraphics.push(circleGraphic);
      container.addChild(circleGraphic);

      // Create image sprite for the circle's logo
      let imageSprite = null;
      if (circle.image) {
        imageSprite = new PIXI.Sprite(PIXI.Texture.from(circle.image));
        imageSprite.anchor.set(0.5);

        // Calculate whether to use full size based on the circle's radius
        const isFullSize = circle.radius * 0.3 < 5;
        const spriteScale = isFullSize ? 0.5 : 0.3;

        // Set the initial width and height of the image sprite
        imageSprite.width = circle.radius * spriteScale;
        imageSprite.height = circle.radius * spriteScale;

        // Set the initial position of the image sprite
        imageSprite.position = {
          x: 0,
          y: isFullSize ? 0 : -circle.radius / 2,
        };

        container.addChild(imageSprite);
      }
      imageSprites.push(imageSprite); // Maintain array alignment

      const text = PixiUtils.createText(circle);
      container.addChild(text);
      textSprites.push(text);

      const text2 = PixiUtils.createText2(circle, bubbleContents.value);
      container.addChild(text2);
      text2Sprites.push(text2);

      container.interactive = true;
      container.buttonMode = true;

      container.on("mousedown", () => {
        setIsModalOpen(true);
        setSelectedData(circle);
      });

      container.on("touchend", () => {
        setIsModalOpen(true);
        setSelectedData(circle);
      });

      // Event handlers can be added here if needed

      app.stage.addChild(container);
      // eslint-disable-next-line no-undef
      globalThis.__PIXI_APP__ = app;
    });

    const ticker = BubblesUtils.update(
      circles,
      imageSprites,
      textSprites,
      text2Sprites,
      circleGraphics
    );

    setTimeout(() => {
      if (app?.ticker) app.ticker.add(ticker);
    }, 200);

    return () => {
      app.ticker.remove(ticker);
      appContainer?.removeChild(app.view);
      app.destroy(true, { children: true });
    };
  }, [circles, bubbleContents]);

  const openModal = (data) => {
    setIsModalOpen(true);
    setSelectedData(data);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };

  useEffect(() => {
    if (circles) {
      const max = maxCircleSize;
      const min = minCircleSize;
      circles.forEach((circle) => {
        if (!circle[bubbleSort]) return;
        const givenFactor = scalingFactor !== Infinity ? scalingFactor : 1;

        const circleData = circle[bubbleSort];

        const radius = Math.abs(Math.floor(circleData * givenFactor));
        const numericValue = parseFloat(circle.text2.text);
        circle.targetRadius = radius > max ? max : radius > min ? radius : min;
        circle.color =
          circle[bubbleSort] > 0
            ? colorScheme.success.name
            : circle[bubbleSort] < 0
            ? colorScheme.danger.name
            : colorScheme.neutral.name;

        if (circle.text2 && bubbleContents?.label === "Performance") {
          circle.text2.text = bubbleContentFormator(
            bubbleSort,
            circle[bubbleSort]
          );
        } else if (circle.text2 && bubbleContents?.label !== "Performance") {
          circle.text2.text = bubbleContentFormator(
            bubbleContents?.value,
            circle[bubbleContents?.value]
          );
        }
      });
    }
  }, [bubbleSort, circles, scalingFactor, colorScheme, bubbleContents]);

  return (
    <>
      <div className="w-full overflow-hidden z-50" ref={appRef}></div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedData(null);
          }}
          data={selectedData}
        >
          <ChartData selectedData={selectedData} />
        </Modal>
      )}
    </>
  );
}
