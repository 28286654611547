const HeroButton = ({
  text,
  color,
  borderColor,
  activeValue,
  setActiveButton,
  short,
}) => {
  const isActive = Boolean(
    short ? short === activeValue : text.toLowerCase() === activeValue
  );
  return (
    <button
      type="button"
      className={`filterButtons pb-2.5 pt-4 px-5 mb-1 text-xl text-white focus:outline-none ${
        isActive ? `bg-[${color}] bg-opacity-70 hover:bg-opacity-50` : "bg-[#444444] hover:bg-white hover:bg-opacity-50"
      } rounded-lg border-x-2 border-b-2`}
      style={{ borderColor: borderColor }}
      onClick={() => setActiveButton(short ?? text.toLowerCase())}
    >
      {text}
    </button>
  );
};

export default HeroButton;
