import * as PIXI from "pixi.js";
import { maxCircleSize } from "../pixi/pixiHelpers";
import {
  bubbleContentFormator,
  bubbleContentFormator2,
  // bubbleContentFormator3,
} from "../../utils/formators";

const gradientTextureCache = new Map();

export class PixiUtils {
  static createContainer(circle) {
    const container = new PIXI.Container();
    container.hitArea = new PIXI.Circle(0, 0, circle.targetRadius);

    // Make it interactive/clickable to receive hover events
    container.cursor = "pointer";
    container.eventMode = "dynamic";

    // ----- ADD THESE LINES -----
    // When the pointer is over/out, toggle the circle.hovered flag
    container.on("pointerover", () => {
      circle.hovered = true;
    });
    container.on("pointerout", () => {
      circle.hovered = false;
    });


    // ---------------------------

    return container;
  }

  static createImageSprite(circle) {

    // Example image URL, adapt as needed:
    const imgUrl = `/assets/coins/bitcoin.png`;

    const imageSprite = PIXI.Sprite.from(imgUrl);
    const isFullSize = circle.radius * 0.3 < 10;
    imageSprite.anchor.set(0.5);
    imageSprite.width = circle.radius * (isFullSize ? 1.2 : 0.5);
    imageSprite.height = circle.radius * (isFullSize ? 1.2 : 0.5);
    imageSprite.position = { x: 0, y: isFullSize ? 0 : -circle.radius / 2 };
    return imageSprite;
  }

  static createText(circle) {
    const fontSize = circle.radius * 0.4;

    const textStyle = new PIXI.TextStyle({
      fontSize: fontSize + "px",
      fontWeight: 800,
      fill: "#ffffff",
    });

    const text = new PIXI.Text(circle.symbol.toUpperCase(), textStyle);
    text.anchor.set(0.5);
    text.position.y = 0.1 * circle.radius;
    return text;
  }

  static createText2(circle, content, activeValue) {
    let filter = localStorage.getItem("selectedFilter") || "";
    let data = null;
    const multiplicationFactor = content?.includes("Performance") ? 0.25 : 0.2;
    const fontSize = circle.radius * multiplicationFactor;

    const text2Style = new PIXI.TextStyle({
      fontSize: fontSize + "px",
      fontWeight: 800,
      fill: "#ffffff",
    });

    // Apply the appropriate formatter, depending on the filter
    if (filter === "volumeP") {
      data =
        circle[content] !== null
          ? bubbleContentFormator2(content, circle.volumeP)
          : "No data";
    } else if (filter === "PerChange") {
      data =
        circle[content] !== null
          ? bubbleContentFormator(content, circle[content])
          : "No data";
    } else if (filter === "volume") {
      data =
        circle[content] !== null
          ? bubbleContentFormator("volume", circle[content])
          : "No data";
    } else {
      data =
        circle[content] !== null
          ? bubbleContentFormator(content, circle[content])
          : "No data";
    }

    const text2 = new PIXI.Text(data, text2Style);
    text2.anchor.set(0.5);
    text2.position.y = circle.radius / 1.5;
    circle.text2 = text2;

    return text2;
  }

  static createGradientTexture(radius, color) {
    const textureKey = `${radius}_${color}`;

    if (gradientTextureCache.has(textureKey)) {
      return gradientTextureCache.get(textureKey);
    }

    const canvas = document.createElement("canvas");
    canvas.width = radius;
    canvas.height = radius;
    const context = canvas.getContext("2d");

    if (context) {
      const gradient = context.createRadialGradient(
        radius / 2,
        radius / 2,
        0,
        radius / 2,
        radius / 2,
        radius / 2
      );
      const orignalRad = radius;
      const percentage = orignalRad / maxCircleSize;

      const colorPercentage =
        percentage > 0.35
          ? percentage
          : percentage > 0.35 && percentage < 0.75
          ? 1
          : 0.35;

      switch (color) {
        case "green":
          gradient.addColorStop(0, "rgba(60, 179, 113, 0)");
          gradient.addColorStop(0.42, "rgba(60, 179, 113, 0.15)");
          gradient.addColorStop(0.5, `rgba(60, 179, 113, ${colorPercentage})`);
          break;
        case "red":
          gradient.addColorStop(0, "rgba(255, 0, 0, 0)");
          gradient.addColorStop(0.42, "rgba(255, 0, 0, 0.15)");
          gradient.addColorStop(0.5, `rgba(255, 0, 0, ${colorPercentage})`);
          break;
        case "blue":
          gradient.addColorStop(0, "rgba(8, 44, 127, 0)");
          gradient.addColorStop(0.42, "rgba(8, 44, 127, 0.15)");
          gradient.addColorStop(0.5, `rgba(8, 44, 127, ${colorPercentage})`);
          break;
        case "yellow":
          gradient.addColorStop(0, "rgba(240, 122, 0, 0)");
          gradient.addColorStop(0.42, "rgba(240, 122, 0, 0.15)");
          gradient.addColorStop(0.5, `rgba(240, 122, 0, ${colorPercentage})`);
          break;
        // **Added Case for Brown Color**
        case "brown":
          gradient.addColorStop(0, "rgba(165, 42, 42, 0)");
          gradient.addColorStop(0.42, "rgba(165, 42, 42, 0.15)");
          gradient.addColorStop(
            0.5,
            `rgba(165, 42, 42, ${colorPercentage})`
          );
          break;
        case "lightGray":
          gradient.addColorStop(0, "rgba(211, 211, 211, 0)");
          gradient.addColorStop(0.42, "rgba(211, 211, 211, 0.15)");
          gradient.addColorStop(
            0.5,
            `rgba(211, 211, 211, ${colorPercentage})`
          );
          break;
        default:
          gradient.addColorStop(0, "rgba(255, 255, 255, 0)");
          gradient.addColorStop(1, "rgba(255, 255, 255, 0.1)");
          break;
      }

      context.fillStyle = gradient;
      context.beginPath();
      context.arc(radius / 2, radius / 2, radius / 2 / 2, 0, Math.PI * 2);
      context.fill();

      const texture = PIXI.Texture.from(canvas);
      gradientTextureCache.set(textureKey, texture);

      return texture;
    }

    return PIXI.Texture.from(canvas);
  }
}
