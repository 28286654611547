import { isNumber, isString } from "lodash";
import store from './../reducers/slices/SettingsSlice';

export const formatNumber5 = (value, locale = "en-US") => {
  if (value === null || value === undefined) return "0";

  let valueGenerated = value;
  let suffix = "";

  if (value >= 1e3 && value < 1e6) {
    valueGenerated = value / 1e3;
    suffix = "K";
  } else if (value >= 1e6 && value < 1e9) {
    valueGenerated = value / 1e6;
    suffix = "M";
  } else if (value >= 1e9 && value < 1e12) {
    valueGenerated = value / 1e9;
    suffix = "B";
  } else if (value >= 1e12 && value < 1e15) {
    valueGenerated = value / 1e12;
    suffix = "T";
  } else if (value >= 1e15 && value < 1e18) {
    valueGenerated = value / 1e15;
    suffix = "Qa";
  } else if (value >= 1e18 && value < 1e21) {
    valueGenerated = value / 1e18;
    suffix = "Qi";
  } else if (value >= 1e21 && value < 1e24) {
    valueGenerated = value / 1e21;
    suffix = "Sx";
  } else if (value >= 1e24 && value < 1e27) {
    valueGenerated = value / 1e24;
    suffix = "Sp";
  } else if (value >= 1e27 && value < 1e30) {
    valueGenerated = value / 1e27;
    suffix = "Oc";
  } else if (value >= 1e30 && value < 1e33) {
    valueGenerated = value / 1e30;
    suffix = "No";
  } else if (value >= 1e33) {
    valueGenerated = value / 1e33;
    suffix = "Dc";
  }

  return (
    new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 1, // Adjust as needed
    }).format(valueGenerated) + suffix
  );
};

export const formatCurrency = (value, locale = "en-US", currency = "NGN") => {
  if (value) {
    let valuegenerated = value;
    let lastValue = "";
    if (value >= 1000 && value < 1000000) {
      valuegenerated = value / 1000;
      lastValue = " K";
    } else if (value >= 1000000 && value < 1000000000) {
      valuegenerated = value / 1000000;
      lastValue = " M ";
    } else if (value >= 1000000000 && value < 1000000000000) {
      valuegenerated = value / 1000000000;
      lastValue = " B";
    } else if (value >= 1000000000000 && value < 1000000000000000) {
      valuegenerated = value / 1000000000000;
      lastValue = " Qd";
    } else if (value >= 1000000000000000 && value < 1000000000000000000) {
      valuegenerated = value / 1000000000000000;
      lastValue = " Qi";
    } else if (value >= 1000000000000000000 && value < 1000000000000000000000) {
      valuegenerated = value / 1000000000000000000;
      lastValue = " Sx";
    } else if (
      value >= 1000000000000000000000 &&
      value < 1000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000;
      lastValue = " Sp";
    } else if (
      value >= 1000000000000000000000000 &&
      value < 1000000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000000;
      lastValue = " Oc";
    } else if (
      value >= 1000000000000000000000000000 &&
      value < 1000000000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000000000;
      lastValue = " No";
    } else if (value >= 1000000000000000000000000000000) {
      valuegenerated = value / 1000000000000000000000000000000;
      lastValue = " Dc";
    }
    return (
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(valuegenerated) + lastValue
    );
  }
  return "0";
};
export const formatCurrency2 = (value, locale = "en-US", currency = "NGN") => {
  if (value) {
    let valuegenerated = value;
    let lastValue = "";
    if (value >= 1000 && value < 1000000) {
      valuegenerated = value / 1000;
      lastValue = " K Units";
    } else if (value >= 1000000 && value < 1000000000) {
      valuegenerated = value / 1000000;
      lastValue = " M Units";
    } else if (value >= 1000000000 && value < 1000000000000) {
      valuegenerated = value / 1000000000;
      lastValue = " B Units";
    } else if (value >= 1000000000000 && value < 1000000000000000) {
      valuegenerated = value / 1000000000000;
      lastValue = " Qd Units";
    } else if (value >= 1000000000000000 && value < 1000000000000000000) {
      valuegenerated = value / 1000000000000000;
      lastValue = " Qi Units";
    } else if (value >= 1000000000000000000 && value < 1000000000000000000000) {
      valuegenerated = value / 1000000000000000000;
      lastValue = " Sx Units";
    } else if (
      value >= 1000000000000000000000 &&
      value < 1000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000;
      lastValue = " Sp";
    } else if (
      value >= 1000000000000000000000000 &&
      value < 1000000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000000;
      lastValue = " Oc";
    } else if (
      value >= 1000000000000000000000000000 &&
      value < 1000000000000000000000000000000
    ) {
      valuegenerated = value / 1000000000000000000000000000;
      lastValue = " No";
    } else if (value >= 1000000000000000000000000000000) {
      valuegenerated = value / 1000000000000000000000000000000;
      lastValue = " Dc";
    }
    return (
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(valuegenerated) + lastValue
    );
  }
  return "0";
};


export const bubbleContentFormator2 = (content, value) => {
  
  const adjustedValue = Math.floor(value * 100) / 100;
  const formattedValue = formatCurrency(Math.floor(value * 100) / 100).replaceAll("NGN", "")
  return `₦ ${formattedValue}`;
};

export const bubbleContentFormator3 = (content, value) => {
  
  if (isString(content))
    return `${
      content === "volume" ? "" : !content?.includes("Performance") ? "₦" : ""
    }${
      content?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency2(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.includes("Performance") ? "%" : ""}`;
  else {
    return `${
      content?.value === "volume" ? "" : !content?.value?.includes("Performance") ? "₦" : ""
    }${
      content?.value?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency2(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.value?.includes("Performance") ? "%" : ""}`;
  }
};

export const bubbleContentFormatorCloseOpen = (content, value) => {
  
  if (content !== 'Volume'){
    return `${
      content === "Volume" ? "" : !content?.includes("Performance") ? "₦" : ""
    }${
      content?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.includes("Performance") ? "%" : ""}`;

  } else if (content === 'Volume'){
    return `${
      content === "Volume" ? "" : !content?.includes("Performance") ? "" : ""
    }${
      content?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.includes("Performance") ? "%" : ""}`;

  }
    
  else {
    return `${
      content?.value === "Volume" ? "" : !content?.value?.includes("Performance") ? "₦" : ""
    }${
      content?.value?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.value?.includes("Performance") ? "%" : ""}`;
  }
};

export const bubbleContentFormator = (content, value) => {
  
  if (content !== 'volume'){
    return `${
      content === "volume" ? "" : !content?.includes("Performance") ? "₦" : ""
    }${
      content?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.includes("Performance") ? "%" : ""}`;

  } else if (content === 'volume'){
    return `${
      content === "volume" ? "" : !content?.includes("Performance") ? "₦" : ""
    }${
      content?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency2(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.includes("Performance") ? "%" : ""}`;

  }
    
  else {
    return `${
      content?.value === "volume" ? "" : !content?.value?.includes("Performance") ? "₦" : ""
    }${
      content?.value?.includes("Performance")
        ? Math.floor(value * 100) / 100
        : formatCurrency(Math.floor(value * 100) / 100).replaceAll("NGN", "")
    }${content?.value?.includes("Performance") ? "%" : ""}`;
  }
};

export const roundToOneDecimal = (value) => {
  if (isNumber(value)) return Number(value?.toFixed(2));
};

