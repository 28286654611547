import * as PIXI from "pixi.js";

const appConfig = {
  width: typeof window !== "undefined" ? window.innerWidth - 16 : 100,
  height: typeof window !== "undefined" ? window.innerHeight * 0.94 : 100,
  speed: 0.005,
  elasticity: 0.005,
  wallDamping: 0.5,
  maxCircleSize: 50,
  minCircleSize:
    window !== "undefined"
      ? window.innerWidth
        ? window.innerWidth > 920
          ? 30
          : 15
        : 15
      : 15,
};

export const {
  wallDamping,
  width,
  height,
  speed,
  elasticity,
  maxCircleSize,
  minCircleSize,
} = appConfig;

export const getScalingFactor = (data) => {
  if (!data) return 1;
  const max = data.map((item) => Math.abs(+item["volume"]));
  let totalSquare = 0;

  for (let i = 0; i < max.length; i++) {
    const area = Math.PI * max[i] * max[i];
    totalSquare += area;
  }
  return Math.sqrt((width * height) / totalSquare) * (width > 920 ? 0.8 : 0.5);
};

export const createText = (bubble) => {
  const fontSize = bubble.radius * 0.42;
  const isTextVisible = fontSize > 10;

  const textStyle = new PIXI.TextStyle({
    fontSize: isTextVisible ? fontSize + "px" : 0,
    fill: "#ffffff",
  });
  const symbolArray = bubble.symbol.toUpperCase().split("");
  const newSymbol =
    symbolArray?.length > 6
      ? `${symbolArray.slice(0, 3).join("")}..${symbolArray.slice(-3).join("")}`
      : symbolArray?.join("");
  const text = new PIXI.Text(newSymbol, textStyle);
  text.anchor.set(0.5);
  text.position.x = bubble.radius;
  text.position.y = bubble.radius;
  return text;
};

export const createText2 = (bubble, bubbleSort = "dailyPerformance") => {
  const fontSize = bubble.radius * 0.34;
  const isTextVisible = fontSize > 10;

  const text2Style = new PIXI.TextStyle({
    fontSize: isTextVisible ? fontSize + "px" : 0,
    fill: "#ffffff",
  });
  const data = bubble[bubbleSort];

  const text2 = new PIXI.Text(data, text2Style);
  text2.anchor.set(0.5);
  text2.position.x = bubble.radius;
  text2.position.y = bubble.radius * 1.45;
  bubble["text2"] = text2;

  return text2;
};
