import React, { useCallback, useState } from "react";
import Settings from "./Settings";
import { useSelector } from "react-redux";
import { sectors } from "./Constant";
import { setSelectedPagination } from "../reducers/slices/StockSlice";
import { dispatch } from "../reducers/configureStore";
import { setSelectedSector,setselectedMenueSetting,setSelectedMenu } from "../reducers/slices/SettingsSlice";
import SearchBar from "./SearchBar";
import './Header.css'; 
import  {  useRef, useEffect } from 'react';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const selectedSector = useSelector((state) => state.Settings.selectedSector);
  const pagination = useSelector((state) => state.Stocks.pagination);
  const selectedPage = useSelector((state) => state.Stocks.selectedPage);
  const selectedMenueSetting = useSelector((state) => state.Settings.selectedMenueSetting);

  const handleModalToggle = () => setIsModalOpen((prev) => !prev);
  const [showSearch, setShowSearch] = useState(false);
  const searchContainerRef = useRef(null);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);

  // Update `isSmallScreen` when the window is resized
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 450);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowSearch(false);
      }
    };

    // Add event listener only when search is shown
    if (showSearch) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearch]);

  const handlePageChange = useCallback((value) => {
    if (value) {
      dispatch(setSelectedPagination(value));
    }
  }, []);
  // const handlePageChange = useCallback((value) => {
  //   if (value) {
  //     // dispatch(setSelectedPagination(value));
  //   }
  // }, []);
  const handleOpen = () => {
    setIsModalOpen(true);
    dispatch(setselectedMenueSetting(true));

  };
  const generatePaginationOptions = (totalPages) => {
    const options = [];
    const step = 50;
  
    for (let i = step; i <= totalPages; i += step) {
      const start = i - step + 1; 
      const end = i;
  
      if (start >= 1) {
        options.push(`${start} - ${end}`);
      }
    }
  
    if (totalPages % step !== 0) {
      const lastStart = Math.floor(totalPages / step) * step + 1;
      options.push(`${lastStart} - ${totalPages}`);
    }
  
    return options;
  };
  const selectedOptions = generatePaginationOptions(pagination);
  const handleClose = () => {
    setIsModalOpen(false);
    dispatch(setselectedMenueSetting(false));
    dispatch(setSelectedMenu(1));


  };

  function isMobile() {
    return  window.innerWidth <= 1100; // Adjust the breakpoint as needed
  }
  
  const handleSectorChange = useCallback((value) => {
    if (value) {
        if(isMobile()){
          dispatch(setSelectedPagination("1-30"));
        }
        // else{
        //   dispatch(setSelectedPagination("1-200"));
        // }
      dispatch(setSelectedSector(value));
    }
  }, []);


  return (
    <header
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
      }}
      className=" lg:px-6 md:px-3 bg-[#444444] text-white customNav"
    >
      <nav className="border-gray-200 ">
        <div className="flex  justify-between items-center ">
          <a href="/" className="flex items-center">
            <img
              src={
                colorScheme.success.name === "green"
                  ? "./SBN_ICON.png"
                  : "./SBN_ICON.png"
              }
              className="h-[40px] sm:h-[95px] webLogo "
              alt=" Logo"
            />
    {(!showSearch || !isSmallScreen) && (
        <h1 className="text17 logoText">STOCK BUBBLES</h1>
      )}          </a>
          {!showSearch && (
        <button
          onClick={() => setShowSearch(true)}
          className="search-toggle-button text-sm font-medium px-2 py-2 bg-[#737373] border border-gray-400 rounded-full focus:outline-none ml-2 searchIcon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="25"
            height="25"
            viewBox="0 0 256 256"
          >
            <g
              fill="#ffffff"
              fillRule="nonzero"
              stroke="none"
              strokeWidth="1"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="10"
              strokeDasharray=""
              strokeDashoffset="0"
              fontFamily="none"
              fontWeight="none"
              fontSize="none"
              textAnchor="none"
              style={{ mixBlendMode: "normal" }}
            >
              <g transform="scale(5.12,5.12)">
                <path d="M21,3c-9.37891,0 -17,7.62109 -17,17c0,9.37891 7.62109,17 17,17c3.71094,0 7.14063,-1.19531 9.9375,-3.21875l13.15625,13.125l2.8125,-2.8125l-13,-13.03125c2.55469,-2.97656 4.09375,-6.83984 4.09375,-11.0625c0,-9.37891 -7.62109,-17 -17,-17zM21,5c8.29688,0 15,6.70313 15,15c0,8.29688 -6.70312,15 -15,15c-8.29687,0 -15,-6.70312 -15,-15c0,-8.29687 6.70313,-15 15,-15z"></path>
              </g>
            </g>
          </svg>
        </button>
      )}


     
          <div ref={searchContainerRef}
        className={`relative w-full  inline-flex lg:w-[355px] md:w-[355px] sm:w-[350px]  ${
          showSearch ? "block" : "search-bar-container hidden"
        }`}>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only outline-none"
            >
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              {/* <input
                type="search"
                id="default-search"
                className="block w-full p-2  pl-10 text-sm text-gray-400 rounded-lg focus:ring-purple-500 bg-[#363636]"
                placeholder="Search Stocks...."
                required
              /> */}
              <SearchBar />
            </div>
          </div>

          <div className="flex items-center md:justify-start justify-between md:w-auto w-full lg:order-2 header-second-part">
            <div className="flex gap-2">
            <select
                className="text-sm font-medium md:max-w-[170px] max-w-[110px]  px-3 py-2 bg-[#737373] border border-gray-400 rounded-lg focus:outline-none"
                value={selectedSector}
                onChange={(e) => handleSectorChange(e.target.value)}
                disabled={selectedAsset.name !== "Equity"}
              >
                {sectors.map((sector) => (
                  <option key={sector.name} value={sector.name}>
                    {sector.name}
                  </option>
                ))}
              </select>

              {/* <select
  className="text-sm max-w-[170px] font-medium px-3 py-2 bg-[#737373] border border-gray-400 rounded-lg focus:outline-none"
  value={selectedPage ? selectedPage : ""}
  onChange={(e) => {
    const selectedValue = e.target.value;
    handlePageChange(selectedValue);
  }}
>
  {selectedOptions.length > 0 ? (
    selectedOptions.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))
  ) : (
    <option disabled>No pages available</option>
  )}
</select> */}

              {/* <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-900 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  ></path>
                </svg>
              </div> */}
            </div>
            <div className="flex">
              {/* scroll to table button */}
              <button
                className="text-sm font-medium px-2 py-2 bg-[#737373] border border-gray-400 rounded-full focus:outline-none ml-2"
                onClick={() => {
                  window.scrollTo({
                    top: window.innerHeight * 1.1, // 110vh of the viewport height
                    behavior: "smooth", // Smoth scroll ka liya
                  });
                }}
              >
                <svg viewBox="0 -960 960 960" className="w-6 h-6 text-white ">
                  <path
                    fill="white"
                    d="M120-200v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Z"
                  ></path>
                </svg>
              </button>
              {/* settings modal ka button */}
              <button
                className="text-sm font-medium px-2 py-2 bg-[#737373] border border-gray-400 rounded-full focus:outline-none ml-2"
                onClick={handleOpen}
              >
                <svg viewBox="0 0 24 24" className="w-6 h-6 text-white">
                  <path
                    fill="white"
                    d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {selectedMenueSetting ? <Settings onClose={handleClose} /> : null}
    </header>
  );
};

export default Header;
