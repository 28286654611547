import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import './BubbleSection.css'; 

import HeroButton from "./Buttons/HeroButton";
import Bubbles from "./PixiNext/Bubbles";
// import LoaderModal from "./Modal/LoaderModal";
import { setSelectedPagination } from "../reducers/slices/StockSlice";
import { setPagination } from "../reducers/slices/StockSlice";

import {
  setHideLoader,
  setSelectedFilter,
  setShowLoader,
} from "../reducers/slices/SettingsSlice";
import { setDailyStocks } from "../reducers/slices/StockSlice";

// Function to transform all data
const transformAllData = (dataArray) => {
  return dataArray?.map((item, index) => ({
    id: item.id || index.toString(),
    symbol: item.Symbol || 'N/A',
    dailyPerformance: (item.PerChange !== 0) ? item.PerChange : 0,
    weeklyPerformance: item.weeklyPerformance ?? item.WeeklyChange ?? 0,
    monthlyPerformance: item.monthlyPerformance ?? item.MonthChange ?? 0,
    yearlyPerformance: item.yearlyPerformance ?? item.YearChange ?? 0,
    yearToDatePerformance: item.yearlyToDatePerformance ?? item.YTDChange ?? 0,
    volume: item.Volume ?? item.Volume ?? 0,
    marketCap: item.marketCap ?? 0,
    price: item.Last ?? 0, // Ensure price is included
    rank: item.rank ?? index + 1,
    PerChange: item.PerChange ?? item.PerChange ?? 0,
    // Compute volume * price for 'mtr' filter
    volumeP: item.Value,
    maxPerformance: item.maxPerformance,
    Last: item.Last
    // Add other fields as needed
  }));
};

const BubbleSection = () => {
  const dispatch = useDispatch();
  // Selectors to access Redux state
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  // const loading = useSelector((state) => state.Settings.loader);
  const activeButton = useSelector((state) => state.Settings.selectedFilter);
  const selectedSector = useSelector((state) => state.Settings.selectedSector);
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);
  const allData = useSelector((state) => state.Settings.allData);
  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);
  const selectedMenue = useSelector((state) => state.Settings.selectedMenue);
  // const Stocks = useSelector((state) => state.Stocks.dailyStocks);

  useEffect(() => {
    const fetchData = async () => {
      let alpha = true;
      let count = 1;
      try {
        dispatch(setShowLoader());
        while (alpha) {
          const filters = {
            endDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            startDate: moment()
              .subtract(count, "days")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss"),
            volume: true,
          };

          if (selectedSector !== "ALL SECTORS") {
            filters.sector = selectedSector;
          }
          if (selectedAsset) {
            filters.asset = selectedAsset.id;
          }

          // Filter stocks based on selected asset and sector
          const filteredStocks = allData?.filter((item) => {
            if (selectedAsset.name === "Equity") {
              if (selectedSector === "ALL SECTORS") {
                return item.Asset === "EQUITY";
              } else {
                return (
                  item.Asset === "EQUITY" && item.Sector === selectedSector
                );
              }
            } else if (selectedAsset.name === "Debt") {
              return item.Asset === "DEBT";
            } else if (selectedAsset.name === "ETF") {
              return item.Asset === "ETF";
            } else if (selectedAsset.name === "All") {
              return true;
            }
            return false;
          });

          const transformedAllData = transformAllData(filteredStocks);
        

          let processedData = transformedAllData;

          // Apply specific sorting/filtering based on activeButton
          switch (activeButton) {
            case "dailyPerformance":
            case "weeklyPerformance":
            case "monthlyPerformance":
            case "yearlyPerformance":
            case "yearToDatePerformance":
              // Filter out records where the selected performance metric is exactly 0
              processedData = transformedAllData
                ?.filter(item => item[activeButton] !== 0) // Exclude items with value === 0
                .sort((a, b) => {
                  const aValue = a[activeButton];
                  const bValue = b[activeButton];
                  return bValue - aValue; // Sort in descending order
                });
              break;
          
            case "volume":
              // Filter out records where volume is exactly 0
            
              processedData = transformedAllData
                ?.filter(item => item.volume !== 0) // Exclude items with volume === 0
                .sort((a, b) => b.volume - a.volume); // Sort by volume in descending order
              break;
              
            case "volumeP":
              // Filter out records where volumeP is exactly 0
              processedData = transformedAllData
                ?.filter(item => item.volumeP !== 0) // Exclude items with volumeP === 0
                .sort((a, b) => b.volumeP - a.volumeP); // Sort by volumeP in descending order
              break;
            case "PerChange":
              // Filter items where PerChange (price change) is 0
              processedData = transformedAllData?.filter(item => item.PerChange === 0);
              break;

            default:
              // Default sorting if needed
              break;
          }


          if (processedData?.length) {
            // const [startStr, endStr] = selectedPagination.split("-");
            // const startIndex = parseInt(startStr, 10) - 1;
            // const endIndex = parseInt(endStr, 10);
            const paginatedStocks = processedData
            const stockData = paginatedStocks?.map((item) => ({
              ...item,
              // Ensure monthlyPerformance has a default value if needed
              monthlyPerformance: item?.monthlyPerformance ?? 0,
            }));
            
            dispatch(setDailyStocks(stockData));
            dispatch(setPagination(stockData.length ));

            alpha = false;
          } else {
            if (count > 3) {
              dispatch(setDailyStocks([]));
              alpha = false;
            } else {
              count++;
            }
          }

        }
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setHideLoader());
      }
    };

    fetchData();
    // Include dependencies
  }, [
    dispatch,
    selectedSector,
    selectedAsset,
    allData,
    selectedPagination,
    activeButton, // Re-fetch when filter changes
  ]);

  
  function isMobile() {
    return  window.innerWidth <= 1100; // Adjust the breakpoint as needed
  }
  // Handler to set the active button based on user interaction
  const handleSetActiveButton = (data) => {
    
  
      if(isMobile()){
        dispatch(setSelectedPagination("1-30"));
      }
      // else{
      //   dispatch(setSelectedPagination("1-200"));
      // }
      

    let text;
    switch (data) {
      case "day":
        text = "dailyPerformance";
        break;
      case "week":
        text = "weeklyPerformance";
        break;
      case "month":
        text = "monthlyPerformance";
        break;
      case "yoy":
        text = "yearlyPerformance";
        break;
      case "ytd":
        text = "yearToDatePerformance";
        break;
      case "tac":
        text = "volume"; // Maps to 'volume' in transformed data
        break;
      case "mtr":
        text = "volumeP"; // Maps to 'volumeP' in transformed data
        break;
      case "uch":
        text = "PerChange"; // Used for filtering unchanged prices
        break;
      default:
        text = data;
    }
    localStorage.setItem('selectedFilter', text);
    dispatch(setSelectedFilter(text));
  };

  return (
    <section  className={
      `bg-[#222222] relative h-[91vh] overflow-clip` + 
      (selectedMenue !== 1 ? ' bubblesScreen' : '')
    }>
      <div className=" -top-2 left5is w-full -z-5 mb-2">
        <div className="flex flex-row gap-1 md:max-w-full overflow-auto">
          <HeroButton
            text="Day"
            short="dailyPerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Week"
            short="weeklyPerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Month"
            short="monthlyPerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="YoY"
            short="yearlyPerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="YTD"
            short="yearToDatePerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Top Active"
            short="volume"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Most Traded"
            short="volumeP"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
          <HeroButton
            text="Unchanged"
            short="PerChange"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={handleSetActiveButton}
          />
        </div>
      </div>
      <Bubbles />
      {/* {loading && <LoaderModal />} */}
    </section>
  );
};

export default BubbleSection;
