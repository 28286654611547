// AreaChart.js
import React, { useCallback, useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { useSelector } from "react-redux";
import { bubbleContentFormatorCloseOpen } from "../../utils/formators";
import { useState, useEffect } from 'react';

const AreaChart = ({ data = [], metric = "close" }) => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const [chartHeight, setChartHeight] = useState(
    window.innerWidth <= 600 ? 160 : 180
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        if (window.innerHeight <= 850) {
          setChartHeight(160);
        } else {
          setChartHeight(160);
        }
      } else {
        if (window.innerHeight <= 850) {
          setChartHeight(160);
        } else {
          setChartHeight(180);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define mapping based on metric
  const metricMapping = {
    price: {
      field: "Close",
      name: "Close Price",
      color: "#0d5edb",
      annotationsColor: "danger",
    },
    volume: {
      field: "Volume",
      name: "Volume",
      color: "#f39c12",
      annotationsColor: "warning",
    },
    // Add more metrics if needed
  };

  const selectedMetric = metricMapping[metric] || metricMapping.price;

  const calculateData = useCallback((stock) => {
    // Destructure TradeDate array
    const [year, month, day] = stock.TradeDate;

    // Create a UTC timestamp
    const date = Date.UTC(year, month - 1, day);

    // Get the value based on the selected metric
    const value = stock[selectedMetric.field];

    // Ensure both date and value are valid
    if (date && value !== undefined) {
      return [date, value];
    }

    return null;
  }, [selectedMetric.field]);

  const selectedData = useMemo(() => {
    if (data.length) {
      const processedData = data
        ?.map(calculateData)
        .filter(Boolean)
        .sort((a, b) => a[0] - b[0]); // Sort by timestamp
      return processedData;
    }
    return [];
  }, [data, calculateData]);

  const minYValue = useMemo(() => {
    const yValues = selectedData.map((item) => item[1]);
    return yValues.length ? Math.min(...yValues) : 0;
  }, [selectedData]);

  const maxYValue = useMemo(() => {
    const yValues = selectedData.map((item) => item[1]);
    return yValues.length ? Math.max(...yValues) : 0;
    
  }, [selectedData]);

  const chartData = useMemo(
    () => (
      {
        series: [{ name: selectedMetric.name, data: selectedData }],
        options: {
          chart: {
            id: "area-datetime",
            type: "area",
            toolbar: {
              show: false,
            },
            zoom: {
              autoScaleYaxis: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: "hollow",
          },
          stroke: {
            curve: 'smooth',
            width: 1,
            colors: ['#FFFFFF'],
          },
          xaxis: {
            type: "datetime",
            min: selectedData[0]?.[0] || undefined,
            tickAmount: 6,
            labels: {
              show: false, // Hide X-axis labels
            },
            axisBorder: {
              show: false, // Hide X-axis border
            },
            axisTicks: {
              show: false, // Hide X-axis ticks
            },
          },
          yaxis: {
            min: selectedMetric.field === "Volume" ? 0 : minYValue * 0.65,
            max: selectedMetric.field === "Volume" ? maxYValue * 1.2 : maxYValue * 1.05,
            labels: {
              show: false, // Hide Y-axis labels
            },
            axisBorder: {
              show: false, // Hide Y-axis border
            },
            axisTicks: {
              show: false, // Hide Y-axis ticks
            },
            // Optional: Remove Y-axis title if not needed
            title: {
              text: '', // Empty string to hide the title
            },
          },
          annotations: {
            yaxis: [
              {
                y: minYValue,
                borderColor: colorScheme[selectedMetric.annotationsColor]?.color || "#000",
                label: {
                  borderColor: "transparent",
                  // borderColor: '#FF0000' || "#000",
                  position: 'right',
                  offsetX: 10,
                  offsetY: 20,   
                  style: {
                    zIndex: "1000",
                    color: "#FF0000",
                    fontWeight: "800",
                    background: "transparent",
                    // background: '#FF0000' || "#000",
                    fontSize: "15px", 
                  },
                  text: `${bubbleContentFormatorCloseOpen(selectedMetric.name, minYValue)}`,
                },
              },
              {
                y: maxYValue,
                borderColor: colorScheme[selectedMetric.annotationsColor]?.color || "#000",
                label: {
                  borderColor: "transparent",
                  // borderColor: '#008000' || "#000",
                  position: 'right',
                  offsetX: 10,
                  offsetY: -5,
                  style: {
                    zIndex: "1000",
                    color: "#008000",
                    fontWeight: "800",
                    // background: '#008000' || "#000",
                    background: "transparent",
                    fontSize: "15px",
                  },
                  text: `${bubbleContentFormatorCloseOpen(selectedMetric.name, maxYValue)}`,
                },
              },
            ],
          },          
          tooltip: {
            x: {
              format: "dd MMM yyyy",
            },
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
            },
            onDatasetHover: {
              highlightDataSeries: true,
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100],
              colorStops: [
                {
                  offset: 0,
                  color: selectedMetric.color,
                  opacity: 0.7,
                },
                {
                  offset: 100,
                  color: selectedMetric.color,
                  opacity: 0.9,
                },
              ],
            },
          },
          grid: {
            show: false, // Already hiding the grid
          },
          // Optional: Remove legend if not needed
          legend: {
            show: false,
          },
        },
      }
    ),
    [selectedData, minYValue, maxYValue, colorScheme, selectedMetric]
  );
  console.log("selectedData:", selectedData);

  return (
    <div id="chart" style={{ width: '98%', margin: 0, padding: 0 }}>
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="area"
        width="98%"
        height={chartHeight}
      />
    </div>
  );
};

export default AreaChart;
