// ChartData.js
import React, { useEffect, useState, useMemo } from "react";
import AreaChart from "../Charts/AreaChart";
import Tabs from "../Tabs";
import axios from "axios";
import moment from "moment";
import { graphValues } from "../../utils/constants"; // Should include "Price" and "Volume"
import Dropdown from "../Dropdown";
import { useSelector } from "react-redux";
import StockStatsCard from "./components/StockStatsCard";

const ChartData = ({ selectedData }) => {
  // State Variables
  const [selectedTimeRange, setSelectedTimeRange] = useState("weekly"); // 'weekly', 'monthly', 'yearly', 'allTime'
  const [selectedMetric, setSelectedMetric] = useState(graphValues[0]); // Initialize with "Price"
  const [stockData, setStockData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [stockVolumes, setStockVolumes] = useState({
    weekly: 0,
    monthly: 0,
    yearly: 0,
    allTime: 0,
  });
  const [stockPerformance, setStockPerformance] = useState({
    weekly: selectedData.weeklyPerformance,
    monthly: selectedData.monthlyPerformance,
    yearly: selectedData.yearlyPerformance,
    allTime: selectedData.maxPerformance,
  });

  // Redux Selector for color scheme
  const colorScheme = useSelector((state) => state.Settings.colorScheme);

  /**
   * Filters stock data within a date range, inclusive of startDate and endDate.
   */
  const filterDataByRange = (stockData, startDate, endDate) =>
    stockData.filter((stock) => {
      let tradeDate;

      // Handle TradeDate as array [year, month, day] or as a string
      if (Array.isArray(stock.TradeDate)) {
        const [year, month, day] = stock.TradeDate;
        tradeDate = moment([year, month - 1, day]).startOf('day');
      } else {
        tradeDate = moment(stock.TradeDate).startOf('day');
      }

      return tradeDate.isBetween(startDate, endDate, undefined, "[]");
    });

  /**
   * Calculates the total volume.
   */
  const calculateVolume = (filtered) => {
    if (!filtered.length) return 0;
    const totalVolume = filtered.reduce((accumulator, currentItem) => {
      const volume = typeof currentItem.Volume === 'number' ? currentItem.Volume : 0;
      return accumulator + volume;
    }, 0);

    return totalVolume;
  };

  /**
   * Calculates the performance percentage.
   */
  const calculatePerformance = (data) => {
    if (data.length < 2) return 0;
    const initialClose = data[0]?.Close ?? 0;
    const finalClose = data[data.length - 1]?.Close ?? 0;
    return initialClose !== 0 ? ((finalClose - initialClose) / initialClose) * 100 : 0;
  };

  /**
   * Determines the end date based on selectedData or the latest stock data.
   */
  const determineEndDate = (stockData) => {
    let endDate = moment().startOf("day"); // Default to today
    if (selectedData?.TradeDate) {
      let parsedDate;
      if (Array.isArray(selectedData.TradeDate)) {
        const [year, month, day] = selectedData.TradeDate;
        parsedDate = moment([year, month - 1, day]);
      } else {
        parsedDate = moment(selectedData.TradeDate);
      }

      if (parsedDate.isValid()) {
        endDate = parsedDate.startOf("day");
      } else {
        console.warn("Invalid TradeDate in selectedData:", selectedData.TradeDate);
      }
    } else if (stockData.length > 0) {
      const latestStockDate = stockData[stockData.length - 1].TradeDate;
      let parsedLatestDate;
      if (Array.isArray(latestStockDate)) {
        const [year, month, day] = latestStockDate;
        parsedLatestDate = moment([year, month - 1, day]);
      } else {
        parsedLatestDate = moment(latestStockDate);
      }

      if (parsedLatestDate.isValid()) {
        endDate = parsedLatestDate.startOf("day");
      } else {
        console.warn("Invalid latest TradeDate in stockData:", latestStockDate);
      }
    }
    return endDate;
  };

  /**
   * Fetches stock data from the API and processes it.
   */
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        setIsLoading(true);
        const symbol = selectedData?.Symbol ?? selectedData.symbol;

        // Make a GET request to your API endpoint
        const response = await axios.get(
          `https://stockbubbles.com.ng/api/stocks/get-chart-data/${symbol}`
        );

        if (response.data) {
          const fetchedData = response.data;

          // Ensure data is sorted by TradeDate in ascending order
          const sortedStockData = fetchedData.sort((a, b) =>
            moment(a.TradeDate).diff(moment(b.TradeDate))
          );
          setStockData(sortedStockData);

          // Determine endDate based on available data
          const endDate = determineEndDate(sortedStockData);

          // Define time ranges for volume and performance calculations
          const timeRanges = [
            { key: "weekly", days: 7 },
            { key: "monthly", days: 30 },
            { key: "yearly", days: 365 },
          ];

          const volumeUpdates = {};
          const performanceUpdates = {};

          timeRanges.forEach((range) => {
            // Current Period
            const currentStartDate = moment(endDate).subtract(range.days, 'days').startOf('day');
            const currentFiltered = filterDataByRange(sortedStockData, currentStartDate, endDate);
            volumeUpdates[range.key] = calculateVolume(currentFiltered);
            performanceUpdates[range.key] = calculatePerformance(currentFiltered);
          });

          // All-Time Volume and Performance
          volumeUpdates.allTime = calculateVolume(sortedStockData);
          performanceUpdates.allTime = calculatePerformance(sortedStockData);

          // Update stockVolumes and stockPerformance
          setStockVolumes(volumeUpdates);
          // setStockPerformance(performanceUpdates); // Uncommented to update performance
        }
      } catch (e) {
        console.error("Error fetching stock data:", e);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedData) {
      fetchStockData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]); // Removed dependencies on stockData to prevent infinite loops

  /**
   * Memoized filtered stock data based on selectedTimeRange.
   */
  const filteredStockData = useMemo(() => {
    if (!stockData.length) return [];

    // Determine endDate based on available data
    const endDate = determineEndDate(stockData);

    let startDate = null;

    // Calculate startDate based on selectedTimeRange
    if (selectedTimeRange === "weekly") {
      startDate = moment(endDate).subtract(7, "days").startOf("day");
    } else if (selectedTimeRange === "monthly") {
      startDate = moment(endDate).subtract(30, "days").startOf("day");
    } else if (selectedTimeRange === "yearly") {
      startDate = moment(endDate).subtract(365, "days").startOf("day");
    } else if (selectedTimeRange === "allTime") {
      // Use the full data range
      if (Array.isArray(stockData[0].TradeDate)) {
        const [year, month, day] = stockData[0].TradeDate;
        startDate = moment([year, month - 1, day]).startOf("day");
      } else {
        startDate = moment(stockData[0].TradeDate).startOf("day");
      }
    }

    if (startDate) {
      console.log(`startDate for ${selectedTimeRange}: ${startDate.format("YYYY-MM-DD")}`);
      console.log(`end Date for ${selectedTimeRange}: ${endDate.format("YYYY-MM-DD")}`);
    }

    if (startDate && endDate) {
      const filtered = filterDataByRange(stockData, startDate, endDate);
      console.log(stockData, startDate, endDate, "(stockData, startDate, endDate)",filtered,"filtered");
      return filtered;
    } else {
      return stockData;
    }

  }, [selectedTimeRange, stockData]); // Removed selectedData from dependencies

  return (
    <div>
      {/* Graph Value Dropdown */}
      <div className="flex items-center justify-end p-4 pb-0 text-white">
        <Dropdown
          buttonText="Graph Value"
          data={graphValues}
          value={selectedMetric}
          handleInput={(data) => setSelectedMetric(data)}
        />
      </div>

      {/* Loading State */}
      {!loading ? (
        <>
          {/* Stock Statistics Card */}
          <StockStatsCard
            data={{
              ...selectedData,
              weeklyPerformance: stockPerformance.weekly,
              monthlyPerformance: stockPerformance.monthly,
              yearlyPerformance: stockPerformance.yearly,
              allTimePerformance: stockPerformance.allTime,
              weeklyVolume: stockVolumes.weekly,
              monthlyVolume: stockVolumes.monthly,
              yearlyVolume: stockVolumes.yearly,
              allTimeVolume: stockVolumes.allTime,
            }}
          />

          {/* Area Chart */}
          <AreaChart data={filteredStockData} metric={selectedMetric.name.toLowerCase()} />
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-4 md:p-5">
          <img
            src="./assets/animated.gif" // Assuming the loader is the same regardless of colorScheme
            className="w-[20%]"
            alt="Loader"
          />
          <p className="text-white">Analyzing Data ....</p>
        </div>
      )}

      {/* Performance Tabs */}
      <Tabs
        stockPerformance={stockPerformance}
        stockVolumes={stockVolumes}
        selectedTimeRange={selectedTimeRange}
        setSelectedTimeRange={setSelectedTimeRange}
        selectedMetric={selectedMetric}
      />
    </div>
  );
};

export default ChartData;
