// Tabs.js
import { useSelector } from "react-redux";
import { roundToOneDecimal, formatNumber5 } from "../../utils/formators";

const Tabs = ({
  stockPerformance,
  stockVolumes,
  selectedTimeRange,
  setSelectedTimeRange,
  selectedMetric,
}) => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);

  const isVolume = selectedMetric.name.toLowerCase() === "volume";

  const filters = [
    {
      id: "weekly",
      label: "Week",
      value: isVolume ? stockVolumes.weekly : stockPerformance.weekly,
    },
    {
      id: "monthly",
      label: "Month",
      value: isVolume ? stockVolumes.monthly : stockPerformance.monthly,
    },
    {
      id: "yearly",
      label: "Year",
      value: isVolume ? stockVolumes.yearly : stockPerformance.yearly,
    },
    {
      id: "allTime",
      label: "Max",
      value: isVolume ? stockVolumes.allTime : stockPerformance.allTime,
    },
  ];

  const getFormattedValue = (value) => {
    return isVolume ? formatNumber5(value) : roundToOneDecimal(value);
  };

  const getUnit = () => {
    return isVolume ? "Units" : "%";
  };

  const getValueColor = (value) => {
    if (isVolume) {
      // For volume, use a consistent color, e.g., blue
      return "text-blue-500";
    }
    return value >= 0 ? "text-green-500" : "text-red-500";
  };

  const handleTabClick = (id) => {
    setSelectedTimeRange(id);
  };

  return (
    <div className="text-sm font-medium text-center text-white p-1">
      <ul className="flex flex-wrap justify-between -mb-px">
        {filters.map(({ id, label, value }) => {
          const formattedValue = getFormattedValue(value);
          const isSelected = selectedTimeRange === id;
          const valueColor = getValueColor(value);

          return (
            <li
              key={id}
              className={`inline-block px-5 py-2 cursor-pointer hover:bg-[#585858] rounded-lg ${
                isSelected ? "bg-[#585858]" : ""
              }`}
              onClick={() => handleTabClick(id)}
            >
              <button
                className={`px-2 py-1 rounded-lg ${
                  isSelected
                    ? isVolume
                      ? "bg-blue-500"
                      : value >= 0
                      ? "bg-green-500"
                      : "bg-red-500"
                    : ""
                }`}
              >
                {label}
              </button>
              <p className={`${valueColor} text-lg m-0`}>
                {formattedValue} {getUnit()}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
